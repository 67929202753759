// Build url for Api calls to get data for a table
export default function(tableId) {

  // Set element from tableId
  const el = $('#' + tableId);

  // Set params based on filter attributes
  let params = {};
  const fromDate = el.attr('data-api-from-date');
  const scopeId = el.attr('data-api-scope-id');
  const scopeType = el.attr('data-api-scope-type');
  const scopes = el.attr('data-scopes');
  const userId = el.attr('data-user-id');
  const responsibleUsers = el.attr('data-responsible-users');
  const accountId = el.attr('data-account-id');
  const questionnaireId = el.attr('data-questionnaire-id');
  const questionId = el.attr('data-question-id');
  const insideOut = el.attr('data-inside-out');
  const itemId = el.attr('data-item-id');
  const token = el.attr('data-report-token');
  const clientId = el.attr('data-client-id');
  const companyId = el.attr('data-company-id');
  const portfolioId = el.attr('data-portfolio-id');
  const modelId = el.attr('data-model-id');
  const ownerId = el.attr('data-owner-id');
  const clientType = el.attr('data-client-type');
  const temperature = el.attr('data-temperature');
  const metricId = el.attr('data-metric-id');
  const methodologyId = el.attr('data-methodology-id');
  const deadline = el.attr('data-deadline');
  const portfolioType = el.attr('data-portfolio-type');
  const year = el.attr('data-year');
  const calculationType = el.attr('data-calculation-type');
  const reportType = el.attr('data-report-type');
  const reportScope = el.attr('data-report-scope');
  const recordCount = el.attr('data-api-record-count');
  const companyIds = el.attr('data-api-company-ids');
  const fundIds = el.attr('data-api-fund-ids');
  const slug = el.attr('data-slug');
  const paymentStatus = el.attr('data-payment-status');
  const country = el.attr('data-country');
  const accountType = el.attr('data-account-type');
  const status = el.attr('data-status');
  const itemStatus = el.attr('data-item-status');
  const rating = el.attr('data-rating');

  // Set formula id
  let formulaId = $('#formula_filter').attr('data-api-formula-id');
  if (typeof formulaId === 'undefined') {formulaId = el.attr('data-api-formula-id');}

  // Add to params if exist as data attrs on table
  if (typeof fromDate !== 'undefined') { params['from_date'] = fromDate; }
  if (typeof userId !== 'undefined') { params['user_id'] = userId; }
  if (typeof responsibleUsers !== 'undefined') { params['responsible_users'] = responsibleUsers; }
  if (typeof accountId !== 'undefined') { params['account_id'] = accountId; }
  if (typeof questionnaireId !== 'undefined') { params['questionnaire_id'] = questionnaireId; }
  if (typeof questionId !== 'undefined') { params['question_id'] = questionId; }
  if (typeof portfolioId !== 'undefined') { params['portfolio_id'] = portfolioId; }
  if (typeof year !== 'undefined') { params['year'] = year; }
  if (typeof calculationType !== 'undefined') { params['calculation_type'] = calculationType; }
  if (typeof methodologyId !== 'undefined') { params['methodology_id'] = methodologyId; }
  if (typeof itemId !== 'undefined') { params['item_id'] = itemId; }
  if (typeof token !== 'undefined') { params['token'] = token; }
  if (typeof temperature !== 'undefined') { params['temperature'] = temperature; }
  if (typeof clientId !== 'undefined') { params['client_id'] = clientId; }
  if (typeof clientType !== 'undefined') { params['client_type'] = clientType; }
  if (typeof reportType !== 'undefined') { params['report_type'] = reportType; }
  if (typeof portfolioType !== 'undefined') { params['portfolio_type'] = portfolioType; }
  if (typeof scopeId !== 'undefined') { params['scope_id'] = scopeId; }
  if (typeof scopeType !== 'undefined') { params['scope_type'] = scopeType; }
  if (typeof scopes !== 'undefined') { params['scopes'] = scopes; }
  if (typeof recordCount !== 'undefined') { params['length'] = recordCount; }
  if (typeof formulaId !== 'undefined') { params['formula_id'] = formulaId; }
  if (typeof deadline !== 'undefined') { params['deadline'] = deadline; }
  if (typeof companyId !== 'undefined') { params['company_id'] = companyId; }
  if (typeof companyIds !== 'undefined') { params['company_ids'] = companyIds; }
  if (typeof fundIds !== 'undefined') { params['fund_ids'] = fundIds; }
  if (typeof reportScope !== 'undefined') { params['report_scope'] = reportScope; }
  if (typeof paymentStatus !== 'undefined') { params['payment_status'] = paymentStatus; }
  if (typeof country !== 'undefined') { params['country'] = country; }
  if (typeof accountType !== 'undefined') { params['account_type'] = accountType; }
  if (typeof ownerId !== 'undefined') { params['owner_id'] = ownerId; }
  if (typeof modelId !== 'undefined') {params['model_id'] = modelId;}
  if (typeof metricId !== 'undefined') {params['metric_id'] = metricId;}
  if (typeof status !== 'undefined') {params['status'] = status;}
  if (typeof itemStatus !== 'undefined') {params['item_status'] = itemStatus;}
  if (typeof rating !== 'undefined') {params['rating'] = rating;}

  // Set url based on chart data attr
  // Include Api prefix
  let url = el.attr('data-api-url');

  // Return url with params
  return url + '?' + $.param(params);

};
